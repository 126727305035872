* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  color: black;
}

ul {
  padding: 0 !important;
  list-style-type: none;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #0d0b0b;
  height: 80px;
  box-shadow: 0px 5px 5px #e6e4e4;
  width: 100% !important;
}
.header > a {
  text-decoration: none;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
.header > a::first-letter {
  font-size: 30px;
}
.header > a:hover {
  transition: 1s;
  color: #aaa;
  letter-spacing: 2px;
}
.header .dropdown {
  margin: 1px 5px 1px 5px;
  padding: 0 5px;
  align-items: center;
  height: 45px;
  position: relative;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  color: white;
  cursor: pointer;
}
.header .dropdown:hover i {
  transform: translateY(3px);
}
.header .dropdown span {
  margin-left: 10px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}
.header .dropdown i {
  margin: 0 15px;
  font-size: 25px;
  transition: 0.5s;
}
.header .dropdown ul {
  width: 180px;
  position: absolute;
  background-color: rgb(63, 63, 64);
  display: flex;
  flex-direction: column;
  top: 47px;
  right: -2px;
  z-index: 10000;
}
.header .dropdown ul a {
  padding: 5px 10px;
  font-size: 20px;
  font-weight: normal;
  color: white;
}
.header .dropdown ul a:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.SignUp {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.SignUp > div {
  font-size: 40px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.SignUp p {
  font-size: 25px;
  margin-bottom: 20px;
}
.SignUp form {
  width: 100%;
}
.SignUp form input {
  display: block;
  padding: 10px;
  width: 50%;
  margin: 20px auto;
  border-radius: 5px;
  border: 1px solid black;
}
.SignUp form input:focus {
  outline: none;
}
.SignUp form button {
  background-color: black;
  color: white;
  height: 50px;
  display: block;
  padding: 10px 20px;
  width: 50%;
  margin: 20px auto;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;
  text-transform: capitalize;
}
.SignUp form button:hover {
  background-color: white;
  -webkit-text-stroke: 1px rgb(35, 31, 31);
  font-size: 25px;
  letter-spacing: 1px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid black;
}
.SignUp h3 a {
  color: black;
  font-size: 20px;
  text-decoration: underline !important;
}
.SignUp h3 a:hover {
  color: red;
  transition: 0.5s;
}

.App {
  width: 100%;
  overflow: hidden;
}

.dashbord {
  height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  overflow: hidden;
  position: relative;
}
.dashbord h2 {
  font-size: 30px;
  margin: 0 0 20px 0;
  letter-spacing: 1px;
}
.dashbord h2 span {
  font-family: "Courier New", Courier, monospace;
  letter-spacing: 1px;
  color: #aaa;
}
.dashbord p {
  font-size: 20px;
  margin: 0 0 20px;
  font-weight: bold;
}
.dashbord input {
  width: 400px;
  padding: 10px 20px;
  border-radius: 4px;
  border: 2px solid black;
  margin-bottom: 20px;
  outline: none;
  font-size: 20px;
  color: black;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
@media (max-width: 700px) {
  .dashbord input {
    width: 200px;
  }
}
.dashbord button {
  background-color: black;
  color: white;
  display: block;
  height: 50px;
  padding: 10px;
  width: 150px;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.dashbord button:hover {
  background-color: white;
  -webkit-text-stroke: 1px rgb(35, 31, 31);
  font-size: 20px;
  letter-spacing: 1px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid black;
  -webkit-text-stroke: 1px rebeccapurple;
}

.not {
  text-align: center;
}
@media (max-width: 700px) {
  .not {
    font-size: 16px !important;
  }
}

.data {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
@media (max-width: 700px) {
  .data {
    width: 100%;
  }
}
.data .show-goals {
  margin: 10px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.data .show-goals h2 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: capitalize;
  position: relative;
}
.data .show-goals h2 span {
  margin: 15px 0 5px;
  font-size: 16px;
  display: flex;
}
.data .show-goals .icon i {
  margin: 0 10px;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
}
.data .show-goals .icon i:hover {
  font-size: 25px;
  transition: 0.5s;
}
.data .show-goals .icon i:hover:nth-child(1) {
  color: #b41919;
}
.data .show-goals .icon i:hover:nth-child(2) {
  color: #2119b4;
}

.bullets {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 10px;
}
.bullets li:nth-child(2) {
  animation-delay: 0.2s;
}
.bullets li:nth-child(3) {
  animation-delay: 0.4s;
}
.bullets li {
  width: 30px;
  height: 30px;
  background-color: rgb(64, 75, 111);
  margin: 10px;
  border-radius: 50%;
  animation: 2s left_right forwards infinite;
}

.put {
  width: 100%;
  padding: 10px 20px;
  text-align: right;
  border-radius: 5px;
  border: 1px solid black;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  text-transform: capitalize;
  outline: none;
}

@keyframes left_right {
  from {
    opacity: 0;
    transform: translateX(60px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes li {
  from {
    opacity: 0;
    transform: translate(50px);
  }
  to {
    opacity: 1;
    transform: 0;
  }
}/*# sourceMappingURL=Style.css.map */